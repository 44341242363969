import Swiper from 'swiper/swiper-bundle.esm.js';
import 'swiper/swiper-bundle.css';

import Filter from './filter';

window.onload = () => {
  if (!('ontouchstart' in document.documentElement)) {
    document.documentElement.classList.add('no-touch');
  }

  document.querySelector('.header__menuToggle').addEventListener('click', () => {
    document.querySelector('.header').classList.toggle('-isMenuOpen');
  });

  new Filter('.work__list');

  new Swiper('.-work .swiper-container', {
    slidesPerView: 1.3,
    spaceBetween: 30,
    centeredSlides: true,
    watchOverflow: true,
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'fraction',
    },
    navigation: {
      nextEl: '.swiper-arrow.-next',
      prevEl: '.swiper-arrow.-prev',
    },
    breakpoints: {
      1200: {
        slidesPerView: 2.3,
        spaceBetween: 70,
      }
    }
  })

  new Swiper('.-publications .swiper-container', {
    slidesPerView: 1.3,
    spaceBetween: 30,
    centeredSlides: true,
    loop: true,
    watchOverflow: true,
    loopFillGroupWithBlank: true,
    resistanceRatio: 0,
    pagination: {
      el: '.swiper-pagination',
      type: 'fraction',
    },
    navigation: {
      nextEl: '.swiper-arrow.-next',
      prevEl: '.swiper-arrow.-prev',
    },
    breakpoints: {
      1200: {
        slidesPerView: '4.4',
        simulateTouch: false,
        allowTouchMove: false,
      }
    }
  })
};
