export default class Filter {
  constructor(list) {
    this.filter = document.querySelector('.filter');
    this.list = document.querySelector(list);

    if (!this.filter && !this.list) return false;

    this.filterItems = Array.from(this.filter.querySelectorAll(".filter__item"));

    this.init();
  }

  init() {
    this.filterSelection("all");

    this.filterItems.forEach(filterItem => {
      filterItem.addEventListener('click', () => {
        this.filterItems.forEach(filterItem => {
          filterItem.classList.remove('-current');
        });
        filterItem.classList.add('-current');
        let dataFilter = filterItem.getAttribute('data-filter');
        this.filterSelection(dataFilter);
      })
    });
  }

  filterSelection(filter) {
    const itemsToFilter = Array.from(this.list.children);

    itemsToFilter.forEach(item => {
      item.classList.remove('-isHidden');
    })

    if (filter !== "all") {
      itemsToFilter.forEach(item => {
        let itemFilter = item.getAttribute('data-filter');
        if (filter !== itemFilter) {
          item.classList.add('-isHidden');
        }
      })
    }
  }
}
